@import url("//hello.myfonts.net/count/2bc0bb")

@font-face
  font-family 'Formular-Light'
  src url('../fonts/2BC0BB_0_0.eot')
  src url('../fonts/2BC0BB_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2BC0BB_0_0.woff') format('woff'),url('../fonts/2BC0BB_0_0.ttf') format('truetype')

@font-face
  font-family 'Formular'
  src url('../fonts/2BC0BB_1_0.eot')
  src url('../fonts/2BC0BB_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2BC0BB_1_0.woff') format('woff'),url('../fonts/2BC0BB_1_0.ttf') format('truetype')

html
  font $base-font-size/$base-line-hight $base-font-family
  font-smoothing()
  font-weight 300
  font-style normal

h1,h2,h3,h4,h5,h6
b,strong
  font-family 'Formular'
  font-weight normal
  font-style normal

body
  background-color $body-bg-color
  color $base-text-color

a
  color $base-text-color
  transition-property color, background-color
  transition-duration 200ms
  transition-timing-function ease
  &:hover
    text-decoration none
  &:focus
    outline none

::selection
  background rgba($brand-color, 0.33)
  text-shadow none

.page-layout
  z-index 2
  width 100%
  min-width $min-width
  position relative
  overflow hidden

.section
  position relative
  // top -40px
  padding-bottom 40px
  position relative
  width 100%
  min-height 500px
  min-width $min-width
  background-color $body-bg-color
  .ios &
    min-height 0

.section__bg
  position absolute
  top 0
  right 0
  bottom 0
  left 0

.m-top
  margin-top 80px

.container
  position relative
  width 100%
  max-width $max-width
  min-width $min-width
  margin 0 auto
  padding 0 50px
  z-index 10
  text-align center

  &__title1
    display inline-block
    margin-bottom 23px
    font-size em(45px)
    text-transform uppercase
    letter-spacing 0.1em
    font-family 'Formular-Light'
    position relative

    color #ffffff

  &__title2
    margin-bottom 9px
    font-size em(45px)
    font-family 'Formular-Light'
  &__title3
    font-family 'Formular-Light'
    font-size 35px
    line-height 45px
  &__subtitle
    font-family 'Formular-Light'
    padding-top 30px
    font-size em(22px)
    line-height em(27px)
    color #647281
    p
      margin 0
  &__text
    font-size em(17px)
    line-height em(30px)
    font-family 'Formular-Light'
    color $sub-color
    a
      color $sub-color
      text-decoration none
      border-bottom 1px solid rgba($sub-color, 0.3)
      &:hover
        border-bottom: transparent

.container
  &__title1
    color #e5e5e8
    background -webkit-linear-gradient(rgba(255,255,255,1) 25%, #151620 100%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
    &:after
      content ''
      display block
      position absolute
      top 0
      right 0
      bottom 8px
      left 0
      background -webkit-linear-gradient(rgba(255,255,255,0) 25%, #151620 100%)
      pointer-events none
      opacity 0.7
    &:before
      content ''
      display block
      position absolute
      width 400px
      height 2px
      bottom -25px
      left 50%
      margin-left -200px
      background: linear-gradient(to right, rgba(255,255,255,0) 0%, #4d5764 50%, rgba(255,255,255,0) 100%);

#nebula
  opacity 0
  transition opacity 2500ms ease-in 600ms
  &.active
    opacity 1

#logo-slogan
  opacity 0
  transition opacity 2100ms ease-in 3s
  &.active
    opacity 1


.section-init
  position relative
  height 100vh
  .section__inner
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index 0
  .container
    position absolute
    top 0
    right 0
    bottom 3%
    left 0
    margin auto
    display flex
    flex-direction column
    justify-content center
  .section__bg
    overflow hidden
    &:before
      content ''
      display block
      width 6px
      height 6px
      background-color rgba(#151620,0.8)
      position absolute
      top 50%
      left 50%
      border-radius 2px
      box-shadow 0 0 16px #151620, 0 0 14px #151620, 0 0 10px #151620, 0 0 8px #151620, 0 0 6px #151620, 0 0 4px #151620, 0 0 2px #151620
      transform translate(3px,-3px)
  &__logo
    width 391px
    height 49px
    margin 0 auto
    position relative
    opacity 0
    transition all 11s ease
    transform scale(0.88)
    &.active
      opacity 1
      transform scale(1)
    > div
      position absolute
      top 0
      left 0
      width 100%
      height 100%
    #logo101
      transform translateX( -259px ) rotateY( -180deg )
    #logo16
      transform translateY( -3px ) rotateX( 180deg )
  .container__subtitle
    width 800px
    margin 0 auto
    margin-top 50px
    line-height 1.522

.section-video
  position relative
  overflow hidden
  height 100vh
  background-color transparent
  .container
    position absolute
    top 50%
    left 0
    right 0
    height 550px
    margin-top -270px
    &:before
      content ''
      display inline-block
      height 100%
      vertical-align middle
      margin-right -0.25em
    &__title2
      position absolute
      top 50%
      left 40px
      right 40px
      transform translateY(-65%)
      opacity 0
      transition opacity 500ms ease, transform 600ms ease
      &.is-active
        opacity 1
        transform translateY(-50%)
      @media (max-width:1280px)
        font-size em(36px)
  &__descriptor
    position absolute
    bottom 12px
    color #c9cbd5
    font-size em(17px)
    width 50%
    text-align center
    text-indent -70px
    &:last-of-type
      left 50%
      text-indent -20px
  &__canvas
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    margin auto
    width 100%
    height 550px
    max-width $max-width
    min-width $min-width
    z-index 1
    video
      position absolute
      top 0
      left 50%
      min-width 100%
      min-height 100%
      width auto
      height auto
      opacity 0.75
      transform translateX(-50%)
    &:after
      content ''
      display block
      position absolute
      top 0
      left 50%
      bottom 0
      border-left 1px solid rgba(255,255,255,0.13)
    &:before
      content ''
      display block
      position absolute
      top -1px
      left -1999px
      bottom -1px
      right -1999px
      border 1px solid rgba(255,255,255,0.3)
      background #000

.video-title
  position absolute
  top 50%
  left 50%
  width 490px
  text-align left
.video-title__header
  font-size 35px
  font-family 'Formular-Light'
  margin-bottom 25px
.video-title__text
  font-size 17px
  line-height 30px


.section-tell-you
  position relative
  background-color transparent
  // z-index 1
  transition transform 500ms ease
  height 1063px
  .section__bg
    background url('../images/video-bg.png') center 150px no-repeat;
    &:after
      position absolute
      top -200px
      right 0
      bottom 0
      left 0
      display block
      content ''
      background url('../images/dots.png') center center;
      opacity 0.8
      z-index 1
    .play-video
      position absolute
      top 50%
      left 50%
      margin-top 130px
      margin-left -70px
      width 105px
      height 105px
      background url('../images/play.png') 50% 50% no-repeat;
      transform scale(0.9)
      transition transform 100ms ease
      cursor pointer
      z-index 2
    .play-video:hover
      transform scale(1)

.section-quote
  justify-content flex-end
  position relative
  height 700px
  background-color transparent
  margin-top -10px
  .section-holder
    position static
    left 0
    right 0
    height 700px
    &:before
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      display block
      content ''
      background url('../images/dots.png') center center;
      z-index 1
      opacity 0.7
    &:after
      content ''
      position absolute
      display block
      width 2200px
      height 755px
      bottom 0
      left 50%
      margin-left -1100px
      background radial-gradient(ellipse at bottom, #414f65 0%, rgba(255,255,255,0) 70%)
      opacity 0.75
      transform translateZ(0)
      z-index 2
  &.is-fixed .section-holder
    position fixed
    bottom 0
    left 0
    right 0
    z-index -1
  .container
    color #97b5d9
    font-size 22px
    line-height 35px
    font-family 'Formular-Light'
    text-align left
    position absolute
    left 0
    right 0
    bottom 0
    margin 0 auto
    padding-left 450px
    padding-bottom 100px
    z-index 3
    .scroll-animate
      opacity 0
      transform translateX(70px)
      transition opacity 500ms ease, transform 400ms ease
    .scroll-animate + .scroll-animate
      opacity 0
      transform translateX(-70px)
    .scroll-animate.is-active
    .scroll-animate + .scroll-animate.is-active
      opacity 1
      transform translatex(0)
    p span
      margin-left -16px
      margin-right 2px
    .scroll-animate div
      margin-top 40px
      color #FFF
      span
        display block
        font-size 14px
        color #6b7988
        text-transform uppercase
        margin-left 26px
        line-height (20/14)
        margin-top 5px
  .quote-img
    position absolute
    width: 254px
    height: 351px
    bottom 0
    left 0
    background url('../images/1.png') left bottom no-repeat
    &:after
      content ''
      position absolute
      display block
      width 500px
      height 390px
      bottom 0
      left 50%
      margin-left -250px
      background radial-gradient(ellipse at bottom, rgba(#5c6b8d,0.3) 0%, rgba(#5c6b8d,0.2) 50%, rgba(255,255,255,0) 70%)
      opacity 0.65
      transform translateZ(0)
      z-index -1

.section-features
  background-color #161620
  .container
    padding-top 2%
    padding-bottom 5%

.section-feedback
  position relative
  background-color #161620
  .container
    min-height 877px
    overflow hidden
    padding-top 2%
  &:after
    content ''
    position absolute
    display block
    width 2200px
    height 700px
    bottom 0
    left 50%
    margin-left -1100px
    background radial-gradient(ellipse at bottom, #bcfef9 0%, rgba(255,255,255,0) 70%)
    opacity 0.6
    transform translateZ(0)
.feedback-form-wrapper
  position relative
.feedback-form-send
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  font-size 22px
  padding-top 18%
  text-shadow 0px 0px 3px rgba(0,0,0,0.2), 0px 0px 10px rgba(0,0,0,0.3)
  display none
  small
    margin-top 15px
    display block
    font-size 22px
.feedback-form
  width 470px
  margin 0 auto
  padding-top 40px
  transition opacity 200ms ease
  input[type="text"]
  input[type="email"]
  textarea
    display block
    width 100%
    font-size 22px
    line-height 22px
    font-family 'Formular-Light'
    text-transform none
    // letter-spacing 0.15em
    padding 12px 20px
    border-radius 0
    border none
    color #000000
    margin 30px 0
    &::placeholder
      font-size 14px
      line-height 28px
      text-transform uppercase
      color #647281
    &:focus::placeholder
      color #bdc1c5
    &:focus
      box-shadow 0 0 12px 0 rgba(175,255,249,0.6), 0 0 8px 0 rgba(175,255,249,0.6)
      outline none
    &.is-error
      background-color #ffd0d0
  &.is-loading
    opacity 0
    &:after
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display block
      content ""
  textarea
    height 140px
  .feedback-form__submit
    display inline-block
    font-family 'Formular-Light'
    font-weight 300
    margin-top 20px
    letter-spacing 0.1em
    padding 40px 70px 38px
    color #ffffff
    background-color #4e606b
    font-size 18px
    line-height 1
    text-transform uppercase
    text-decoration none
    box-shadow inset 0px -5px 0px 0px rgba(#373a4f,0.5)
    transition-property box-shadow, background-color
    transform translateZ(0)
    &:hover
      background-color rgba(55,58,79,0.6)
      box-shadow inset 0px -10px 0px 0px rgba(55,58,79,0.8)
    &.is-disabled
      opacity 0.4
      color rgba(255,255,255,0.5)
      pointer-events none
      cursor default
.section-feedback__footer
  font-size 14px
  text-transform uppercase
  position relative
  width 260px
  margin 50px auto -30px
  letter-spacing 0.1em
  opacity 0.75
  a
    text-decoration none
    border-bottom 1px solid rgba(255,255,255,0.3)
    &:hover
      border-bottom 1px solid rgba(255,255,255,0)
  // &:before
  //     content ''
  //     display block
  //     position absolute
  //     width 200px
  //     height 2px
  //     bottom -30px
  //     left 50%
  //     margin-left -100px
  //     background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);


.articles-list
  margin 0
  padding 70px 5% 10px
  overflow hidden
  list-style none
  li
    float left
    width 50%
    margin-bottom 60px
  li:nth-child(odd)
    clear both
  a
    font-size 30px
    text-decoration none
    border-bottom 1px solid
    &:hover
      border-bottom-color transparent
  span
    display block
    color #647281
    font-size 20px
    margin-top 15px


@keyframes ctscroll
  0%
    opacity 0
    transform translateY(-15px)
  5%
    opacity 0
  50%
    opacity 1
  75%
    opacity 0
  100%
    opacity 0
    transform translateY(15px)
.ctscroll
  display none
  position fixed
  left 0
  right 0
  bottom 30px
  width 20px
  height 20px
  margin 0 auto
  background url('../images/chevron-down.png')
  z-index 10
  animation ctscroll 1900ms ease infinite


.gz-footer
  width 100%
  padding 0 19%
  margin 50px auto
  clerfix()


.video-progress-bar
  display none
  position absolute
  bottom 20px
  left 0
  right 0
  width 90%
  margin 0 auto
  height 5px
  background-color rgba(255,255,255,0.15)

.video-play-progress
  position absolute
  top 0
  left 0
  width 0
  height 100%
  background-color rgba(255,255,255,0.25)
  transition width 600ms ease



.video-modal
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  overflow hidden
  background-color #19191F
  transform scale(0.2)
  opacity 0
  transition opacity 1000ms ease, transform 400ms ease
  z-index -1
  &.is-active
    opacity 1
    transform scale(1)
    z-index 999
  video
    position absolute
    top 0
    left 0
    right 0
    width 100%
    max-width 1400px
    min-width 1000px
    margin auto
.video-modal-close
  position absolute
  width 22px
  height 22px
  display block
  top 50px
  right 50px
  background url('../images/video-close.png')
  cursor pointer
.video-modal-close:hover
  opacity 0.65
.video-m-controls
  position fixed
  width 920px
  margin 0 auto
  left 0
  right 0
  bottom 70px
  height 40px
  clearfix()
  @media (max-width: 1050px)
    width auto
    left 20px
    right 120px
.video-m-pp
  position relative
  float left
  width 60px
  height 100%
  margin-left 10px
  // background-color #ffffff
  background-image linear-gradient(rgba(#fdfdfd,0.06) 0%, rgba(#717385,0.06) 100%)
  cursor pointer
  &:after
    position absolute
    content ""
    display block
    top 0
    left 0
    right 0
    bottom 0
  &.pause:after
    background url('../images/pp-pause.png') 50% 50% no-repeat
  &.play:after
    background url('../images/pp-play.png') 50% 50% no-repeat
.video-m-timeline
  position relative
  width 100%
  height 100%
  margin-left 80px
  background-image linear-gradient(rgba(#fdfdfd,0.06) 0%, rgba(#717385,0.06) 100%)
  -webkit-transform translateZ(0);
  &:after
    position absolute
    top 0
    bottom 0
    margin auto
    left 20px
    right 20px
    height 1px
    content ""
    display block
    background-color #6b6f85
    z-index 1
.video-m-timeline__item
  position absolute
  padding 0 20px
  margin-top -5px
  line-height 1
  font-size 12px
  text-transform uppercase
  color #6b7988
  z-index 2
  transition 200ms ease
  .dot
    position absolute
    display block
    content ""
    width 7px
    height 7px
    bottom -17px
    background-color #6b6f85
    border-radius 9999px
    transition 200ms ease
    cursor pointer
  span
    opacity 0
    transition 200ms ease
  .dot:hover + span
    opacity 1
  &.is-active
    color #309dd2
    .dot
      background-color #309dd2
    span
      opacity 0
.video-m-timeline__item:nth-child(1)
  left 0
.video-m-timeline__item:nth-child(2)
  left 20%
.video-m-timeline__item:nth-child(3)
  left 44%
.video-m-timeline__item:nth-child(4)
  left 71%
.video-m-timeline__progress
  position absolute
  top 0
  bottom 0
  margin auto
  left 0
  width 0
  height 1px
  display block
  padding 0 20px
  background-clip content-box
  background-color #309dd2
  z-index 2
