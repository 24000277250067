$main-menu
  .csstransforms &
    transition all 400ms ease
  .no-csstransforms &
    transition all 400ms ease

.topbar
  position fixed
  top 0
  width 100%
  height $topbar-height
  background-color #000000
  z-index 301

  @extend $main-menu
  .csstransforms &
    transform translate(0,0)
  .menu-open &
    .csstransforms &
      transform translate(0,$menu-height)
    .no-csstransforms &
      top $menu-height
  .modal-page-open &
    .csstransforms &
      left 0
      right 0
      margin-left auto
      margin-right auto
      max-width $max-width - 100
      min-width $min-width - 100
      transform translate(0,$menu-height+60)
      .menu__cnt
        background-color #ecf2eb
    .no-csstransforms &
      top $menu-height+60
  &__logo
    position absolute
    width 220px
    height 18px
    margin-top -(@height/2)
    margin-left -(@width/2)
    left 50%
    top 50%
.menu
  clearfix()
  &__cnt
    position absolute
    top -($menu-height)
    left 0
    right 0
    height $menu-height
    background-color #ffffff
    overflow hidden
    line-height 0
    font-size 0
    clearfix()
    &:before
      content ''
      display inline-block
      height 100%
      vertical-align middle
      margin-right -0.25em
    a
      font-size $base-font-size
      line-height $base-line-hight
      color #000000
      display inline-block
      padding 5px 10px
      font-size 20px
      text-transform uppercase
      text-decoration none
      letter-spacing 0.05em
      &:hover
        color $brand-color
    .current
      color $base-hover-color
      pointer-events none
      cursor default
  &__inner
    width 100%
    display inline-block
    vertical-align middle
  &__main
    margin-left 30px
    float left
    a ~ a
      margin-left 70px
  &__extra
    margin-right 30px
    float right
  &__trigger
    position absolute
    right 30px
    display block
    width 44px
    height 100%
    cursor pointer
    & span
      position absolute
      top 50%
      left 0
      display block
      width 50%
      margin -2px 25% 0
      height 3px
      background-color #ffffff
      font-size 0px
      -webkit-touch-callout none
      user-select none
      transition background-color 400ms ease
      &:before,
      &:after
        position absolute
        left 0
        width 100%
        height 100%
        background-color #ffffff
        content ''
        @extend $main-menu
      &:before
        transform translate(0,-(@height)*2)
      &:after
        transform translate(0,(@height)*2)
    // &:hover
    //   span
    //     background-color $brand-color
    //     &:before,
    //     &:after
    //       background-color $brand-color
    .menu-open & span
      background-color transparent
    .menu-open & span:before
      transform translate(0,0) rotate(45deg)
    .menu-open & span:after
      transform translate(0,0) rotate(-45deg)

.page-layout-wrapper
  @extend $main-menu
  position relative
  .csstransforms &
    top $topbar-height
  .no-csstransforms &
    top $topbar-height
  .menu-open &
    .csstransforms &
      transform translate(0,$menu-height+60)
    .no-csstransforms &
      top $menu-height

