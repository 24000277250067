.features-list
  &__controls
    margin 80px 0
    display flex
    flex-wrap nowrap
    justify-content space-between
    align-items stretch
    -webkit-transform translateZ(0)
    position relative
    z-index 2
  &__cnt
    height 800px
    position relative
    text-align left
    display none
    z-index 1
    -webkit-transform translateZ(0)
    // margin-bottom 200px

.no-flexbox
  .features-list
    &__controls
      overflow hidden
      display table
  .feature-tab
    display block
    float left
    width 25%
    margin-right 0 !important
.feature-tab
  flex 1
  margin-right 30px
  background-origin content-box
  background-color #232432
  border 1px solid rgba(55,58,79,0)
  // box-shadow inset 0px -5px 0px 0px rgba(55,58,79,0.5)
  text-decoration none
  padding 40px 20px 35px
  transition-property box-shadow, background-color
  transform translateZ(0)
  position relative
  // box-shadow inset 0px -5px 0px rgba(55,58,79,0.65)
  &:after
    position absolute
    content ''
    display block
    top 0
    left 0
    bottom 0
    right 0
    box-shadow inset 0 0 0 5px rgba(55,58,79,0.0)
    transition box-shadow 200ms ease
  &:hover
    background-color #232432
    &:after
      box-shadow inset 0 0 0 5px rgba(55,58,79,0.65)
  &:hover .feature-tab__img:nth-child(2)
    opacity 0
  &.active
    pointer-events none
    cursor default
    background-color #151620
    border 1px solid rgba(55,58,79,0.4)
    box-shadow inset 0px 0px 0px rgba(55,58,79,0.4)
    &:after
      box-shadow inset 0 0 0 5px rgba(55,58,79,0.0)
  &:last-child
    margin-right 0
  &__img
    padding-top 13px
    top 0px
    right 0px
    bottom 5px
    left 0px
    position absolute
    opacity 0
    transition opacity 200ms ease
    transform translateZ(0)
    &:nth-child(1)
      background-color #232432
      opacity 1
    &:nth-child(2)
      background-color #232432
    &:nth-child(3)
      background-color #151620
    img
      display inline-block
  &.active &__img
    bottom 0
  // &:hover &__img:nth-child(2)
  //   opacity 1
  &__img:nth-child(2)
    opacity 1
  &.active &__img:nth-child(3)
    opacity 1
  &__title
    margin-top 100px
    font-size 20px
    line-height 1
    text-transform uppercase
    letter-spacing 0.16em
    font-weight 300
    // transition margin-top 200ms ease-in
    transform translateZ(0)
  &__text
    margin-top 30px
    color #969ab3
    letter-spacing 0.08em
    font-size 15px
    font-weight 300
  &__title
  &__text
    position relative
    z-index 2

.feature
  position absolute
  top 0
  left 0
  width 100%
  clearfix()
  &__subtitle
    font-size 14px
    line-height 25px
    text-transform uppercase
    color #647281
    padding-left 40px
    letter-spacing 0.08em
    padding-bottom 30px
  &__footer
    color #576574
    font-size 15px
    position relative
    margin-top 30px
    padding-top 25px
    &:after
      content ''
      display block
      position absolute
      top 0
      left 0
      width 100%
      height 2px
      width 100%
      background: linear-gradient(to right, rgba(59,66,79,1) 0%, rgba(255,255,255,0) 80%);
  &__bg
    position absolute
    z-index -1
  &__img
    position absolute
    left 0
    top 0
    width 100%
    height 100%
  &__img-label
    position absolute
    z-index 1
    font-family 'Formular-Light'
    font-size 15px
    line-height 22px
    color #6f6d80
    width 200px
    span
      color #FFF
  &__text
    width 37.143%


.feature-mix
  .feature__bg
    @media (max-width: 1200px)
      left -10%
    @media (max-width: 1100px)
      left -19%
  .feature__bg
    top -130px
    left -55px
    width 887px
    height 724px
  .feature__img:nth-child(1)
    background url('../images/f1-1.jpg') no-repeat
  .feature__img:nth-child(2)

    background url('../images/f1-2.jpg') no-repeat
    .feature__img-label
      top 130px
      left 310px
  .feature__img:nth-child(3)
    background url('../images/f1-3.jpg') no-repeat
    .feature__img-label
      top 465px
      left 130px
      text-align right
  .feature__img:nth-child(4)
    background url('../images/f1-4.jpg') no-repeat
    .feature__img-label
      top 590px
      left 300px
      width 240px
      text-align right
  .feature__text
    float right

.feature-heat
  .feature__bg
    top -80px
    right -135px
    width 965px
    height 593px
  .feature__img:nth-child(1)
    background url('../images/f2-1.jpg') no-repeat
    .feature__img-label
      top 527px
      left 260px
      text-align right
  .feature__img:nth-child(2)
    background url('../images/f2-2.jpg') no-repeat
    .feature__img-label
      top 240px
      left 570px
  .feature__text
    float left

.feature-spray
  .feature__bg
    @media (max-width: 1200px)
      left 2%
  .feature__bg
    top 50px
    left 100px
    background url('../images/f3-1.jpg') no-repeat
    video
      box-shadow 0 0 250px rgba(#0100fc, 0.5)
      width 130%
      outline 1px solid rgba(255,255,255,0.2)
      outline-offset 20px
      display block
    &:after
      content ''
      display block
      position absolute
      top 0
      right 20%
      width 100px
      height 100px
      border-radius 40px
      background-color transparent
      box-shadow 0 0 190px rgba(red, 0.7)
      z-index -1
    &:before
      content ''
      display block
      position absolute
      top 0
      right 30%
      width 100px
      height 100px
      border-radius 40px
      background-color transparent
      box-shadow 0 0 190px rgba(green, 1)
      z-index -1
  .feature__text
    float right

.feature-burn
  .feature__bg
    @media (max-width: 1200px)
      right -25%
  .feature__bg
    top -190px
    right -185px
    width 1242px
    height 947px
  .feature__img:nth-child(1)
    background url('../images/f4-1.jpg') no-repeat
  .feature__img:nth-child(2)
    top 278px
    left auto
    right 398px
    width 461px
    height 338px
    background url('../images/f4-2.jpg') no-repeat
    .feature__img-label
      top 210px
      left 220px
      width 240px
      font-size 17px
  .feature__text
    float left

.feature
  .feature__bg
    opacity 0
    transform translate(0px, 0px)
    transition all 200ms ease 0ms
  .feature__img:nth-child(1)
    opacity 1
    transition opacity 200ms ease 1000ms
  .feature__img:nth-child(2)
    opacity 0
    transition opacity 200ms ease 1500ms
  .feature__img:nth-child(3)
    opacity 0
    transition opacity 200ms ease 2000ms
  .feature__img:nth-child(4)
    opacity 0
    transition opacity 200ms ease 2500ms
  .feature__text
    opacity 0
    transform translate(0px, 0px)
    transition opacity 200ms ease 0ms, transform 400ms ease-out 0ms

.feature
  .feature__text
    // line-height 1.4
    transform translate(0px, 15px)

.feature.active
  .feature__bg
    opacity 1
    transform translate(0, 0)
    transition-delay 700ms
  .feature__img:nth-child(1)
  .feature__img:nth-child(2)
  .feature__img:nth-child(3)
  .feature__img:nth-child(4)
    opacity 1
  .feature__text
    opacity 1
    // line-height 1.2
    transform translate(0, 0)
    transition-delay 400ms



.is-folded
  .feature-tab
    .feature-tab__img
      opacity 0 !important
      transition all 0ms 0ms
    .feature-tab__text
      display none
    .feature-tab__title
      margin-top 0