.section-about-us
  //
  // padding-bottom 200px
  // margin-bottom -20px
  position relative
  .container
    position relative
    min-width 100%
    padding 10% 0 0
    height 1669px
    margin-bottom -150px
    &:before
      content ''
      display block
      position absolute
      left 0
      right 0
      top 0
      bottom 0
      background url('../images/team-bg-tile.jpg') center 220px repeat-x
      z-index -1
    &:after
      content ''
      display block
      position absolute
      left 0
      right 0
      top 0
      bottom 0
      background url('../images/team-bg.jpg') center 220px no-repeat
      z-index 1
  .container__title1
  .container__subtitle
    position relative
    z-index 3
    top -100px
.person
  position relative
  top -30px
  max-width $max-width
  margin 0 auto
  z-index 3
  &__item
    display inline-block
    width 30%
    vertical-align top
    margin-top 80px
  &__name
    margin-top 35px
    font-family 'Formular-Light'
    font-size 25px
    line-height 35px
  &__post
    opacity 0.5
    color #97b5d9
    font-size 17px
    margin-top 10px

.person__item:nth-child(1)
  position relative
  left -40px
.person__item:nth-child(3)
  position relative
  right -30px
.person__item:nth-child(n+4)
  margin-top 100px