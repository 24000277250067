/*
 * Border triangles utility.
 *
 * Synopsis:
 *   css-triangle: <size> <color> <direction>
 *
 * Examples:
 *
 *    css-triangle(5px #000 top)
 *      yields:
 *        content: ''
 *        display: block
 *        width: 0
 *        height: 0
 *        border: inset 5px
 *        border-color: transparent transparent #000 transparent
 *        border-bottom-style: solid
 */

css-triangle($triangle-size, $triangle-color, $triangle-direction)
  content ''
  display block
  width 0
  height 0
  border inset $triangle-size
  if ($triangle-direction == top)
    border-color transparent transparent $triangle-color transparent
    border-bottom-style solid
  if ($triangle-direction == right)
    border-color transparent transparent transparent $triangle-color
    border-left-style solid
  if ($triangle-direction == bottom)
    border-color $triangle-color transparent transparent transparent
    border-top-style solid
  if ($triangle-direction == left)
    border-color transparent $triangle-color transparent transparent
    border-right-style solid


/*
 * Clearfix
 */

clearfix()
  &:after
    content ""
    display table
    clear both


/*
 * Hide text
 */

hide-text()
  text-indent 200%
  white-space nowrap
  overflow hidden


/*
 * Font rendering OS X
 */
font-smoothing(active = true)
  if active == true
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
  else
    -webkit-font-smoothing subpixel-antialiased
    -moz-osx-font-smoothing auto