.section-use-case
  .container
    padding 2% 0 4%
.use-case
  margin-top 45px
  text-align left
  &__item
    position relative
    clearfix()
  &__item + &__item
    border-top 1px solid $border-color
  &__img
    position absolute
    z-index -1
  &__cnt
    width 53.572%
    padding 95px 20px 80px
  &__link
    display inline-block
    margin-top 10px
    background-color $btn-color
    padding 11px 20px 10px
    text-decoration none
    pointer-events none
    // &:hover
    //   background-color rgba(55,58,79,0.6)
  &__showall
    display inline-block
    margin-top 65px
    padding 40px 70px 38px
    background-color $btn-color
    font-size 20px
    line-height 1
    text-transform uppercase
    text-decoration none
    box-shadow inset 0px -5px 0px 0px rgba(55,58,79,0.5)
    transition-property box-shadow, background-color
    transform translateZ(0)
    &:hover
      background-color rgba(55,58,79,0.6)
      box-shadow inset 0px -10px 0px 0px rgba(55,58,79,0.8)

.use-case__item:nth-child(1)
  .use-case__img
    width 536px
    height 536px
    top -20px
    left 40px
    transition opacity 200ms ease, transform 450ms ease-out
    @media (max-width:1152px)
      left -1%
    &:before
      content ''
      display block
      position absolute
      top 50%
      left 50%
      width 160px
      height 274px
      border-radius 80px/40px
      background-color rgba(255,255,255,0.12)
      // background-color red
      transform translate(-50%,-59%)
      box-shadow 0 0 180px rgba(255,255,255,0.3)
      z-index -1
      opacity 1
    // &:hover:before
    //   opacity 1
    &:after
      position absolute
      content ''
      display block
      top 0
      left 0
      right 0
      bottom 0
      background url('../images/p1.png') center center no-repeat
  .use-case__cnt
    transform translateX(40px)
    float right
.use-case__item:nth-child(2)
  .use-case__img
    width 617px
    height 562px
    right -50px
    top -20px
    &:before
      content ''
      display block
      position absolute
      top 50%
      left 50%
      width 366px
      height 135px
      // background-color rgba(255,255,255,0.12)
      // background-color red
      transform translate(-50%, -43%) skew(53deg) rotate(-19deg)
      box-shadow 0 0 190px rgba(255,255,255,0.4)
      z-index -1
      opacity 1
      transition opacity 300ms ease-out 150ms
    &:after
      position absolute
      content ''
      display block
      top 0
      left 0
      right 0
      bottom 0
      background url('../images/p2.png') center center no-repeat
  .use-case__cnt
    float left

.use-case__item:nth-child(1)
  .use-case__img
    transform translateX(-40px)
  .use-case__cnt
    transform translateX(40px)
.use-case__item:nth-child(2)
  .use-case__img
    transform translateX(40px)
  .use-case__cnt
    transform translateX(-40px)
.use-case__item
  .use-case__img
    opacity 0
    transition opacity 500ms ease, transform 400ms ease
  .use-case__cnt
    opacity 0
    transition opacity 500ms ease, transform 400ms ease
  &.is-active .use-case__cnt
  &.is-active .use-case__img
    opacity 1
    transform translateX(0)