*
  box-sizing border-box

body
  margin 0

a
  background transparent

a:focus
  outline thin dotted

a:active
a:hover
  outline 0

select:focus
input:focus
textarea:focus
button:focus
  outline #5B9DD9 auto
  outline-offset -1px

q
  quotes "\00ab" "\00bb" "\201e" "\201c"

a img
  border 0

button
input
select
textarea
  font-family inherit
  color inherit
  font-size 100%
  margin 0

button
html input[type="button"]
input[type="reset"]
input[type="submit"]
  -webkit-appearance none
  cursor pointer
  border 0

button[disabled]
html input[disabled]
  cursor default

button::-moz-focus-inner
input::-moz-focus-inner
  border 0
  padding 0

button,
select
  text-transform none

textarea
  overflow auto
  vertical-align top

table
  border-collapse collapse
  border-spacing 0

hr
  display block
  margin 1em 0
  padding 0
  height 1px
  border 0
  border-top 1px solid #ccc

small
  font-size 80%
